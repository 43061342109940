import React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { alpha, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { withRouter } from "react-router";
import Container from "@mui/material/Container";
import { Link, matchPath } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    //marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    maxWidth: "1432px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "240px",
      maxWidth: "calc(99.9% - 240px)",
    },
    [theme.breakpoints.up("xxl")]: {
      paddingLeft: "24px",
    },
    [theme.breakpoints.up("xxxl")]: {
      marginLeft: "auto",
      maxWidth: "1872px",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  pageTitle: {
    position: "relative",
    marginRight: "auto",
  },
  search: {
    position: "relative",
    /*borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },*/
    marginLeft: "auto",
    marginRight: "auto",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  notificationSettingsIcon: {
    position: "absolute",
    top: "0",
    bottom: "0",
    right: "0",
    margin: "auto",
  },
  inputRoot: {
    color: "inherit",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "calc(100% + 2ch)",
      },
    },
    [theme.breakpoints.up("md")]: {
      width: "60vw",
      "&:focus": {
        width: "calc(60vw + 2ch)",
      },
    },
    [theme.breakpoints.up("md")]: {
      width: "30vw",
      "&:focus": {
        width: "calc(30vw + 2ch)",
      },
    },
    collectionSelection: {
      fontSize: "14px",
    },
  },
  popupPaper: {
    width: "100%",
    maxWidth: "50ch",
    height: "100%",
    maxHeight: "500px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "35ch",
    },
  },
  notificationUnread: {
    backgroundColor: "#547890",
    color: "#fff",
    "&:hover": {
      color: "#000",
    },
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));


function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img
          src="/logo192.png"
          alt="logo"
          style={{
            display: "block",
            margin: "auto",
            width: "192px",
            height: "192px",
          }}
        />
      </div>
      <Divider />
      <List>
        {[
          {
            titel: "Das Konzept",
            secondary: "",
            route: "/",
          },
          {
            titel: "Lieferbar",
            secondary: "",
            route: "/spieldirekt_lieferbar",
          },
          {
            titel: "Vorbestellbar",
            secondary: "",
            route: "/spieldirekt_vorbestellbar",
          },
          /*{
            titel: "Pegasus Lieferbar",
            secondary: "",
            route: "/pegasus_lieferbar",
          }*/
        ].map((menuObject, index) => (
          <ListItem disablePadding>
            <ListItemButton
            onClick={mobileOpen ? handleDrawerToggle : undefined}
            component={Link}
            to={menuObject.route}
            key={menuObject.titel}
          >
            <ListItemText
              primary={menuObject.titel}
              secondary={menuObject.secondary}
              key={menuObject.titel}
            />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItem disablePadding>
          <ListItemButton component="a" href="https://spieleordner.de">
            <ListItemText primary={"Spieleordner"} />
          </ListItemButton>
        </ListItem>
        <ListItem>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Container className={classes.container}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={classes.menuButton}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Container>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default withRouter(ResponsiveDrawer);
