import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import ShopDataService from "../services/shop.service";
import { DataGrid, GridToolbar, deDE } from '@mui/x-data-grid';

const useStyles = theme => ({
  root: {
    '& .MuiButton-root': {
      [theme.breakpoints.down('md')]: {
        display: 'inline-block',
      }
    },
    '& .Mui-even': {
      backgroundColor: '#f2f2f2'
    },
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: '#f6f6f6',
      },
    },
  },
});


const sortModel = [
  {
    field: 'title',
    sort: 'asc',
  },
];

class Pegasus extends Component {

  constructor(props) {
    super(props);    

    this.state = {
      shopList: [],
      columns: [],
    }

  }

  componentDidMount() {
    const { status } = this.props;
    this.retrieveShopList(status);
  }

  componentDidUpdate(prevProps){
    if(prevProps.status !== this.props.status){
      const { status } = this.props;
      this.retrieveShopList(status);
    }
  }

  retrieveShopList(status) {
    ShopDataService.getAll('pegasus', status)
    .then(response => {
      const data = [];
      response.data.data.map((val, key) => {
        if (val.date_start !== null) {
          val.date_start = new Date(val.date_start)
        }
        if (val.date_end !== null) {
          val.date_end = new Date(val.date_end)
        }
        data.push(val)
      })
      this.setState({
        shopList: data,
        columns: response.data.meta.columns
      })
    })
    .catch(e => {
      console.log(e);
    });
}
 
  render() {
    const { classes } = this.props;
    return ( 
      <React.Fragment>
    <div className={classes.root} style={{ height: '80vh', width: '100%' }}>
      <DataGrid initialState={{ sortModel: sortModel }} rows={this.state.shopList} columns={this.state.columns} checkboxSelection localeText={deDE.components.MuiDataGrid.defaultProps.localeText} components={{ Toolbar: GridToolbar }} getRowId={(row) => row.ean} />
    </div>
      </React.Fragment>

    )
  }
}

export default withStyles(useStyles)(Pegasus)