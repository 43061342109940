import "./css/App.css";
import React, { Fragment, Suspense } from "react";
import { CssBaseline } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { BrowserRouter, withRouter, Switch, Route } from "react-router-dom";
import { history } from "./helpers/history";
import Container from "@mui/material/Container";

//Components
import AppHeader from "./components/AppHeader";

//Home
import Home from "./pages/Home";

//Spieldirekt
import Spieldirekt from "./pages/Spieldirekt";

//Pegasus
import Pegasus from "./pages/Pegasus";


const useStyles = (theme) => ({
  container: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "240px",
      maxWidth: "calc(99.9% - 240px)",
    },
    [theme.breakpoints.up("xxxl")]: {
      marginLeft: "auto",
      maxWidth: "1920px",
    },
  },
  main: {
    marginTop: "64px",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    /*[theme.breakpoints.between('md','xxl')]: {
        paddingLeft: '240px'
      },*/
  },
});

class MainWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      pageTitle: "",
      alertMessage: false,
      isLoading: true,
      help: false,

    };
    this.setStateValue = this.setStateValue.bind(this);
  }


  setStateValue(key, value) {
    var state = {};
    state[key] = value;
    this.setState(state);
  }


  render() {

    const { classes } = this.props;
    return (
      <Fragment>
        <Suspense>
          <CssBaseline />
              <AppHeader
              pageTitle={this.state.pageTitle}
              notificationCount={this.state.notificationCount}
            />
          <Container className={classes.container}>
            <main className={classes.main}>
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => (
                    <Home {...props}  />
                  )}
                />
                <Route
                  path="/spieldirekt_lieferbar"
                  exact
                  render={(props) => (
                    <Spieldirekt status="lieferbar" {...props}  />
                  )}
                />
                <Route
                  path="/spieldirekt_vorbestellbar"
                  exact
                  render={(props) => (
                    <Spieldirekt status="vorbestellbar" {...props}  />
                  )}
                />
                <Route
                  path="/pegasus_lieferbar"
                  exact
                  render={(props) => (
                    <Pegasus status="lieferbar" {...props}  />
                  )}
                />
                </Switch>
              </main>
            </Container>
        </Suspense>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(MainWrapper));
