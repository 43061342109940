import axios from "axios";

const base_url = process.env.REACT_APP_BASE_URL;

//"Content-type": "application/json"
const http = axios.create({
  baseURL: `${base_url}/api`,
  headers: { "Content-type": "application/json" },
});

export default http;
