import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material";
import { deDE } from '@mui/material/locale';
import { BrowserRouter, } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem'
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      xxxl: 2560,
    }
  },
  palette: {
    primary: {
      main: '#547890',
      white: '#ffffff',
    },
    gainsboro: {
      main: '#e0e0e0',
    }
  },
}, deDE);


ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorkerRegistration.register();


