import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import { H2, Paragraph } from '../components/Typography';
import Pie from "../components/pie";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ShopDataService from "../services/shop.service";

const useStyles = theme => ({

});

const steps = [
  {label: "Pooling der Bestellung", icon: SubscriptionsIcon},
  {label: "Bestellung beim Großhändler", icon: ShoppingCartIcon },
  {label: "Auslieferung", icon: LocalShippingIcon},
  {label: "Fakturierung & Bezahlung der Bestellung", icon: RequestQuoteIcon}
]
  
  class Home extends Component {
    constructor(props) {
      super(props);       

      this.state = {
        data: [],
      }
    }

    componentDidMount() {
      this.retrieveInformation();
    }

  retrieveInformation() {
    ShopDataService.getInformation()
    .then(response => {
      this.setState({
        data: response.data,
      })
    })
    .catch(e => {
      console.log(e);
    });
  }
 

  render() {
    const { data } = this.state;
    return (
    <React.Fragment>
        <H2>Die Idee – Wie alles begann</H2>
        <Paragraph>
            Als passionierte Spielefamilie waren wir auf der Suche für uns selbst günstiger an Brettspiele zu kommen. Wir haben über 450 eigene Spiele (daher auch die Idee zu spieleordner.de 😊 ) und unsere Sammlung wächst weiter.
            Wir haben uns gefragt, ab wann man eigentlich genügend Nachfrage hat, um Spiele direkt beim Großhandel einkaufen zu können.
            Sagen wir so: Wir haben sie erreicht 😊 Die Möglichkeit teilen wir gerne mit Freunden, Bekannten und Interessierten.
        </Paragraph>
        <H2 mt={3}>Das Konzept</H2>
        <Paragraph>
            Wir haben bei uns zu Hause kein Lager mit neuen Spielen die wir weiterverkaufen können oder wollen, sondern wir sammeln so viele Bestellungen, dass wir direkt diese bei einem Großhändler platzieren können. Dadurch haben wir keine Kosten für Lagerhaltung oder gebundenes Kapital!
            Wir bieten daher die Spiele zum Sonderpreis auf Großhandelsniveau an und nehmen nur pro Bestellung 10 Euro pauschal zzgl. Versandkosten für Abwicklung und Handling.
            Wann lohnt sich das Vorgehen?
            Der Einkauf über unser Prinzip lohnt sich nicht, wenn das Spiel sofort benötigt wird oder nur ein sehr günstiges Spiel gekauft wird. Eine Bestellung lohnt sich nur, wenn ein paar Wochen auf die Auslieferung der Bestellung gewartet werden kann und wenn die Bestellung einen Wert erreicht, der die Pauschale und Versandkosten wett macht (Bei Selbstabholung natürlich keine Versandkosten!)
        </Paragraph>
        <H2 mt={3}>Widerrufsmöglichkeiten?</H2>
        <Paragraph>
            Da die Spiele ausschließlich auf Kundenwunsch bestellt werden, gibt es kein Widerrufsrecht! Natürlich gibt es volle Garantie und Gewährleistung direkt durch die Hersteller, eine ordentliche Rechnung etc.
        </Paragraph>
        <H2 mt={3}>Ablauf der Bestellung ganz konkret?</H2>
        <Paragraph>
            Bei Interesse einfach melden, wir gehen gemeinsam die Bestellung durch. Der Einkauf muss nicht im Voraus bezahlt werden, die Bezahlung erfolgt nach Lieferung ganz unkompliziert per Überweisung.
        </Paragraph>
        <Box mt={5}>
          <Stepper alternativeLabel>
            {steps.map((step) =>
            <Step key={step.label} active>
              <StepLabel StepIconComponent={step.icon}>{step.label}</StepLabel>
            </Step>
            )}
        </Stepper>
      </Box>
        <H2 mt={3}>Aktuelle Auslastung der Vormerkung für die nächste Bestellung</H2>
        <Pie key="spieldirekt" percentage={data?.current_order_spieldirekt || 0} colour="green" count={`${data?.current_order_spieldirekt || 0}%`} />
    </React.Fragment>
    )
  }
}

export default withStyles(useStyles)(Home)