import http from "../http-common";
import { handleResponse } from "../helpers/handle-response"


class ShopDataService {
  getAll(vendor, status) {
    return http.get(`/public/games?vendor=${vendor}&status=${status}`)//.then(handleResponse);
  }

  getInformation() {
    return http.get(`/public/information`)//.then(handleResponse);
  }


}

export default new ShopDataService();