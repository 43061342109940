import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import ShopDataService from "../services/shop.service";
import { DataGrid, GridToolbar, deDE } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";

const useStyles = (theme) => ({
  root: {
    "& .MuiButton-root": {
      [theme.breakpoints.down("md")]: {
        display: "inline-block",
      },
    },
    "& .Mui-even": {
      backgroundColor: "#f2f2f2",
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: "#f6f6f6",
      },
    },
  },
});

const columns_lieferbar = [
  { field: "article_number", headerName: "Artikelnummer", width: 125 },
  { field: "title", headerName: "Titel", width: 350 },
  { field: "publisher", headerName: "Verlag", width: 250 },
  { field: "uvp", headerName: "UVP", width: 100 },
  { field: "price", headerName: "Preis in Euro", width: 100 },
  { field: "last_update", headerName: "Zuletzt bearbeitet", width: 150 },
];

const columns_vorbestellbar = [
  { field: "article_number", headerName: "Artikelnummer", width: 125 },
  { field: "title", headerName: "Titel", width: 350 },
  { field: "publisher", headerName: "Verlag", width: 250 },
  { field: "uvp", headerName: "UVP", width: 100 },
  { field: "price", headerName: "Preis in Euro", width: 100 },
  { field: "available_at", headerName: "Lieferbar ab", width: 350 },
  { field: "last_update", headerName: "Zuletzt bearbeitet", width: 150 },
];

const sortModel = [
  {
    field: "title",
    sort: "asc",
  },
];

class MatchHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shopList: [],
      columns: [],
      status: null,
      last_update: "n/A",
    };
  }

  componentDidMount() {
    const { status } = this.props;
    this.setState({
      status: status,
    });
    this.retrieveShopList(status);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      const { status } = this.props;
      this.setState({
        status: status,
      });
      this.retrieveShopList(status);
    }
  }

  retrieveShopList(status) {
    ShopDataService.getAll("spieldirekt", status)
      .then((response) => {
        const data = [];
        response.data.data.map((val, key) => {
          if (val.date_start !== null) {
            val.date_start = new Date(val.date_start);
          }
          if (val.date_end !== null) {
            val.date_end = new Date(val.date_end);
          }
          data.push(val);
        });
        this.setState({
          shopList: data,
          columns: response.data.meta.columns,
          lastUpdate: response.data.meta.last_update,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { classes } = this.props;
    const { shopList, columns, lastUpdate } = this.state;
    return (
      <React.Fragment>
        <div className={classes.root} style={{ height: "80vh", width: "100%" }}>
          <Typography variant="subtitle2" gutterBottom>
            Zuletzt aktualisiert: {lastUpdate}
          </Typography>
          <DataGrid
            initialState={{ sortModel: sortModel }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            rows={shopList}
            columns={columns}
            checkboxSelection
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            getRowId={(row) => row.article_number}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(MatchHistory);
